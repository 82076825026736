<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>Редактирование топика</VToolbarTitle>

            <VSpacer />

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  small
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="removeTopic(testId, topicId)"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удаление топика</span>
            </VTooltip>
          </VToolbar>
          <VCardText>
            <VForm ref="topicForm">
              <VRow>
                <VCol cols="6">
                  <VTextField
                    v-model="topic.name"
                    label="Название топика"
                  />

                  <VBtn
                    depressed
                    color="primary"
                    @click="saveTopic(testId, topicId, topic)"
                  >
                    Сохранить
                  </VBtn>
                </VCol>
              </VRow>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import omit from 'lodash/omit';

export default {
  name: 'TopicEdit',
  inject: ['Names'],
  data() {
    return {
      topic: {},
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
  },
  async created() {
    try {
      const topicResponse = await this.getTopics(this.testId);
      this.topic = topicResponse.find((topic) => topic.id === parseInt(this.topicId, 10));
    } catch (error) {
      this.$di.notify.errorHandler(error);
    }
  },
  methods: {
    async saveTopic(testId, topicId, topic) {
      try {
        await this.$di.api.Talent.topicUpdate({ testId, topicId, ...omit(topic, 'id') });
        this.$di.notify.snackSuccess('Топик успешно отредактирован');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    getTopics(testId) {
      return this.$di.api.Talent.topicsGet({ testId });
    },

    async removeTopic(testId, topicId) {
      try {
        await this.$di.api.Talent.topicDelete({ testId, topicId });
        this.$di.notify.snackSuccess('Топик успешно удалён');

        this.$router.push({ name: this.Names.R_TALENT_TEST_TOPICS, params: { testId: this.testId } });
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
